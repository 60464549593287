function getDomain(host: string) {
  const hostArr = host.split(".");
  if (hostArr.length >= 2) {
    return [hostArr[hostArr.length - 2], hostArr[hostArr.length - 1]].join(".");
  }
  return host;
}

export function isSameDomain(urlStr1: string, urlStr2: string) {
  const url1 = new URL(urlStr1);
  const url2 = new URL(urlStr2);

  const domain1 = getDomain(url1.host);
  const domain2 = getDomain(url2.host);

  return domain1 === domain2;
}
