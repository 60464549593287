import { generatePath } from "ui-kit";

export const paths = {
  listAll: "/events",
  list: "/:groupId/events",
  view: "/:groupId/events/:eventId",
  create: "/:groupId/events/create",
  edit: "/:groupId/events/:eventId/edit",
};

export const generators = {
  listAll: () => generatePath(paths.listAll),
  list: ({ groupId }: Record<"groupId", string>) =>
    generatePath(paths.list, { groupId }),
  view: ({ groupId, eventId }: Record<"groupId" | "eventId", string>) =>
    generatePath(paths.view, { groupId, eventId }),
  create: ({ groupId }: Record<"groupId", string>) =>
    generatePath(paths.create, { groupId }),
  edit: ({ groupId, eventId }: Record<"groupId" | "eventId", string>) =>
    generatePath(paths.edit, { groupId, eventId }),
};
