import {
  Divider,
  ExitToAppIcon,
  FormattedMessage,
  INavBarSection,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  NavBarSection,
  React,
  useAuth,
  useCallback,
  useConfigurationByUserGroupId,
  useContext,
  useGroupFromParamOptional,
  useLocation,
  useMemo,
  useUserGroupsQuery,
} from "ui-kit";

import { LogoutUrlContext } from "../../../contexts/LogoutUrlContext";
import { generators } from "../../../paths";
import { isSameDomain } from "../../../utils/isSameDomain";
import { getNavBarSections } from "./config";
import GroupFilter from "./GroupFilter";

export default function NavContent() {
  const {
    data: { groupId, groupDisplayName },
  } = useGroupFromParamOptional();
  const {
    data: configuration,
    loading: configurationLoading,
    error: configurationError,
  } = useConfigurationByUserGroupId(groupId);
  const { data, loading, error } = useUserGroupsQuery();

  const { logout: authLogout } = useAuth();
  const location = useLocation();
  const { getLogoutUrls } = useContext(LogoutUrlContext);
  const currentPath = location.pathname;

  const groupIds = useMemo(() => {
    if (loading) return [];
    return data?.user_groups.map(({ id }) => id) || [];
  }, [data, loading]);

  const logout = useCallback(() => {
    const logoutUrls = getLogoutUrls();
    let count = logoutUrls.length;
    const returnTo = `${window.location.protocol}//${window.location.host}`;

    logoutUrls.forEach((inputLogoutUrl) => {
      ((logoutUrl: string) => {
        const logoutWin = window.open(
          logoutUrl,
          "_blank",
          "width=1,height=1,top=0,left=0"
        );
        if (logoutWin) {
          const cb = () => {
            count -= 1;
            logoutWin.close();
            if (count === 0) {
              logoutWin.close();
              authLogout({ returnTo });
            }
          };
          if (isSameDomain(window.location.href, logoutUrl)) {
            logoutWin.addEventListener("load", cb, { once: true });
          } else {
            setTimeout(cb, 2000);
          }
        } else {
          count -= 1;
        }
      })(inputLogoutUrl);
    });
    if (count === 0) {
      authLogout({ returnTo });
    }
  }, [authLogout, getLogoutUrls]);

  const navBarSections: INavBarSection[] = getNavBarSections(
    currentPath,
    groupId,
    configuration
  );

  if (loading || configurationLoading) return null;

  if (error) throw error;
  if (configurationError) throw configurationError;

  if (!groupId) {
    return (
      <>
        {currentPath !== generators.groups.list() ? <GroupFilter /> : null}
        <List>
          {navBarSections?.length > 0 && (
            <>
              {navBarSections.map(({ key, title, icon, items }) => (
                <NavBarSection
                  key={key}
                  title={title}
                  icon={icon}
                  items={items}
                  groupIds={groupIds}
                />
              ))}
              <Divider />
            </>
          )}
          <ListItem data-cy="logout" button onClick={() => logout()}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary={<FormattedMessage id="general.logout" />} />
          </ListItem>
        </List>
      </>
    );
  }

  return (
    <>
      <GroupFilter groupId={groupId} groupName={groupDisplayName} />
      <List>
        {navBarSections.map(({ key, title, icon, items }) => (
          <NavBarSection
            key={key}
            title={title}
            icon={icon}
            items={items}
            groupIds={groupId}
          />
        ))}
        <Divider />
        <ListItem data-cy="logout" button onClick={() => logout()}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary={<FormattedMessage id="general.logout" />} />
        </ListItem>
      </List>
    </>
  );
}
