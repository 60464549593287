import {
  AutocompleteInfiniteScroll,
  DataGridFilterProps,
  FormattedMessage,
  makeStyles,
  Maybe,
  React,
  useHistory,
  useLocation,
} from "ui-kit";

import { generators, paths } from "../../../../paths";
import { useCurrentPathGenerators } from "../../../../paths/hooks";
import { useGroupFilter } from "./hooks";

type Props = Pick<
  DataGridFilterProps<string>,
  "setQueryParams" | "className"
> & {
  groupName?: string;
  groupId?: string;
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

// Get the filter to be passed into the Hasura query.
export const getWhereFilter = (bookingStatus: Maybe<string>) => {
  if (!bookingStatus) return {};
  return {
    status: { _eq: bookingStatus },
  };
};

export default function GroupFilter({ className, groupName, groupId }: Props) {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();

  const currentRouteGenerators = useCurrentPathGenerators();
  const { optionValue, setInputValue, options, fetchNext, loading, hasMore } =
    useGroupFilter({
      groupName,
      groupId,
    });

  return (
    <div className={classes.root}>
      <AutocompleteInfiniteScroll
        fetchNext={fetchNext}
        hasMore={hasMore}
        className={className}
        placeholder={<FormattedMessage id="general.group" />}
        options={options}
        onChange={(option) => {
          if (!option) return;
          if (!currentRouteGenerators) {
            history.push({
              pathname: generators.home(),
              search: location.search,
            });
          } else if (option.value) {
            const currentRoute = currentRouteGenerators.list({
              groupId: option.value,
            });
            if (currentRoute === paths.groups.list) {
              history.push({
                pathname: generators.groups.view({ groupId: option.value }),
                search: location.search,
              });
            } else {
              history.push({
                pathname: currentRoute,
                search: location.search,
              });
            }
          } else if (currentRouteGenerators.listAll) {
            history.push({
              pathname: currentRouteGenerators.listAll(),
              search: location.search,
            });
          } else {
            history.push({
              pathname: generators.home(),
              search: location.search,
            });
          }
        }}
        loading={loading}
        value={optionValue}
        onInputChange={setInputValue}
      />
    </div>
  );
}

GroupFilter.defaultProps = {
  groupName: "",
  groupId: "",
};
