import { generatePath } from "ui-kit";

export const paths = {
  listAll: "/credits",
  list: "/:groupId/credits",
  create: "/:groupId/credits/create",
  view: "/:groupId/credits/:accountId",
  edit: "/:groupId/credits/edit/member/:accountId",
};

export const generators = {
  listAll: () => generatePath(paths.listAll),
  list: ({ groupId }: Record<"groupId", string>) =>
    generatePath(paths.list, { groupId }),
  create: ({ groupId }: Record<"groupId", string>) =>
    generatePath(paths.create, { groupId }),
  view: ({ groupId, accountId }: Record<"groupId" | "accountId", string>) =>
    generatePath(paths.view, { groupId, accountId }),
  edit: ({ groupId, accountId }: Record<"groupId" | "accountId", string>) =>
    generatePath(paths.edit, { groupId, accountId }),
};
