import type { RouteType } from "ui-kit";
import { lazy } from "ui-kit";

import { paths } from "../paths/visitors";

export const routes: RouteType[] = [
  {
    path: paths.listAll,
    component: lazy(() => import("../pages/users/List/AllGroups")),
  },
  {
    path: paths.list,
    component: lazy(() => import("../pages/visitors/List")),
  },
  {
    path: paths.view,
    component: lazy(() => import("../pages/visitors/View")),
  },
];
