import type { RouteType } from "ui-kit";
import { generatePath, lazy } from "ui-kit";

export const paths = {
  listAll: "/credits",
  list: "/:groupId/credits",
  create: "/:groupId/credits/create",
  view: "/:groupId/credits/:accountId",
  edit: "/:groupId/credits/edit/member/:accountId",
};

export const generators = {
  listAll: () => generatePath(paths.listAll),
  list: ({ groupId }: Record<"groupId", string>) =>
    generatePath(paths.list, { groupId }),
  create: ({ groupId }: Record<"groupId", string>) =>
    generatePath(paths.create, { groupId }),
  view: ({ groupId, accountId }: Record<"groupId" | "accountId", string>) =>
    generatePath(paths.view, { groupId, accountId }),
  edit: ({ groupId, accountId }: Record<"groupId" | "accountId", string>) =>
    generatePath(paths.edit, { groupId, accountId }),
};

export const routes: RouteType[] = [
  {
    path: paths.listAll,
    component: lazy(() => import("pages/credits/List/AllGroups")),
  },
  {
    path: paths.list,
    component: lazy(() => import("pages/credits/List")),
  },
  {
    path: paths.create,
    component: lazy(() => import("pages/credits/Create")),
  },
  {
    path: paths.view,
    component: lazy(() => import("pages/credits/View")),
  },
  {
    path: paths.edit,
    component: lazy(() => import("pages/credits/Edit")),
  },
];
