import { generatePath } from "ui-kit";

export const paths = {
  listAll: "/visitors",
  list: "/:groupId/visitors",
  view: "/:groupId/visitors/:visitorId",
};

export const generators = {
  listAll: () => generatePath(paths.listAll),
  list: ({ groupId }: Record<"groupId", string>) =>
    generatePath(paths.list, { groupId }),
  view: ({ groupId, visitorId }: Record<"groupId" | "visitorId", string>) =>
    generatePath(paths.view, { groupId, visitorId }),
};
