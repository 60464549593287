import { generatePath } from "ui-kit";

export const paths = {
  listAll: "/users",
  list: "/:groupId/users",
  view: "/:groupId/users/:userId",
  editUserDetails: "/:groupId/users/:userId/edit/details",
  editUserRoles: "/:groupId/users/:userId/edit/roles",
  editUserFields: "/:groupId/users/:userId/edit/user-fields",
  editAdminFields: "/:groupId/users/:userId/edit/admin-fields",
  massEdit: "/:groupId/users/mass-edit",
};

export const generators = {
  listAll: () => generatePath(paths.listAll),
  list: ({ groupId }: Record<"groupId", string>) =>
    generatePath(paths.list, { groupId }),
  view: ({ groupId, userId }: Record<"groupId" | "userId", string>) =>
    generatePath(paths.view, { groupId, userId }),
  editUserDetails: ({
    groupId,
    userId,
  }: Record<"groupId" | "userId", string>) =>
    generatePath(paths.editUserDetails, { groupId, userId }),
  editUserRoles: ({ groupId, userId }: Record<"groupId" | "userId", string>) =>
    generatePath(paths.editUserRoles, { groupId, userId }),
  editUserFields: ({ groupId, userId }: Record<"groupId" | "userId", string>) =>
    generatePath(paths.editUserFields, { groupId, userId }),
  editAdminFields: ({
    groupId,
    userId,
  }: Record<"groupId" | "userId", string>) =>
    generatePath(paths.editAdminFields, { groupId, userId }),
  massEdit: ({ groupId }: Record<"groupId", string>) =>
    generatePath(paths.massEdit, { groupId }),
};
