import { ApplicationPermissions } from "common";
import { useCallback, usePermissions } from "ui-kit";

export function useAdminPermissions() {
  const data = usePermissions();

  const hasPermission = useCallback(
    (permission: string, groupId?: string) =>
      data.hasPermission(permission, groupId) &&
      data.hasPermission(ApplicationPermissions.ADMIN_ACCESS, groupId),
    [data]
  );

  const hasPermissionInAnyGroups = useCallback(
    (permission: string) =>
      data.hasPermissionInAnyGroups(permission) &&
      data.hasPermissionInAnyGroups(ApplicationPermissions.ADMIN_ACCESS),
    [data]
  );

  return {
    ...data,
    hasPermission,
    hasPermissionInAnyGroups,
  };
}
